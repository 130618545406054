<template>
  <div>

    <div class="row">
      <div class="col-md-8 offset-md-2">
        <b-overlay :show="loader" rounded="sm">

          <template #overlay>
            <div>
              <b-spinner small type="grow" variant="danger"></b-spinner>
              <b-spinner type="grow" variant="danger"></b-spinner>
              <b-spinner small type="grow" variant="danger"></b-spinner>
              <span class="sr-only text-dark">Please wait...</span>
            </div>
          </template>

          <div class="card">
            <div class="card-body">
              <div class="float-right" @click="getWithdrawalRecords"><i class="fa fa-sync"/></div>
              <h4 class="header-title mb-3">Withdrawal records</h4>
              <div class="table-responsive mb-0">
                <table class="table table-borderless table-hover table-centered m-0">
                  <thead class="thead-light">
                  <tr>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Account Number</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="data.length<1">
                    <td colspan="2">No records found</td>
                  </tr>
                  <tr v-for="(d,i) in data" :key="i">
                    <td>
                            <span :class="{
                    'bg-soft-success text-success': `${d['trans_status']}` === 'pending',
                    'bg-soft-warning text-warning':
                      `${d['trans_status']}` === 'Upcoming',
                    'bg-soft-danger  text-danger':
                      `${d['trans_status']}` === 'Overdue'
                  }" class="badge">{{ d['trans_status'] }}</span>
                    </td>
                    <td>
                      <h5 class="m-0 font-weight-normal">{{ d['trans_amount'] |to2DecimalPlace }}</h5>
                    </td>
                    <td>{{ d['destination_account_number'] }}</td>


                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- end .table-responsive-->
            </div>
          </div>


        </b-overlay>

      </div>
    </div>
    <!-- end row -->
    <mobilefooter/>

  </div>
</template>

<script>


export default {
  name: "withdrawal-records",
  components: {
    mobilefooter: () => import("@/components/MobileFooter")

  },
  data() {
    return {
      loader: false,
      data: []
    }
  },
  methods: {
    getWithdrawalRecords() {
      this.loader = true;
      this.$store.dispatch('rechargeRecords', 'withdrawal').then((res) => {
        const {status, extra} = res;
        if (status) {
          this.data = extra.data;
        }
      }).finally(() => {
        this.loader = false;
      })
    }
  },
  created() {
    this.getWithdrawalRecords();
  }

}
</script>

<style scoped>

</style>